import './App.css';
import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import Jacobs from '../src/components/views/Jacobs'

import RequestInterceptor from './components/RequestInterceptor';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';

export default function App() {
    useMsalAuthentication("redirect");

    return (
        <>
            <AuthenticatedTemplate>
                <div className="App">
                    <Suspense fallback={<div>Loading...</div>}>
                        <RequestInterceptor>
                            <Jacobs />
                        </RequestInterceptor>
                    </Suspense>
                </div>
            </AuthenticatedTemplate><UnauthenticatedTemplate>
                <p>Authenticating...</p>
            </UnauthenticatedTemplate>
        </>
    );
}
