import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID, // Client ID 
        authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID, // Tenant ID of the React.JS App Registration
        redirectUri: process.env.REACT_APP_BASE_URL,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    //system: {
    //    loggerOptions: {
    //        loggerCallback: (level, message, containsPii) => {
    //            if (containsPii) {
    //                return;
    //            }
    //            switch (level) {
    //                case LogLevel.Error:
    //                    console.error(message);
    //                    return;
    //                case LogLevel.Info:
    //                    console.info(message);
    //                    return;
    //                case LogLevel.Verbose:
    //                    console.debug(message);
    //                    return;
    //                case LogLevel.Warning:
    //                    console.warn(message);
    //                    return;
    //            }
    //        },
    //    },
    //},
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginRequest = {
    scopes: ["api://" + process.env.REACT_APP_SERVER_ID + "/access_as_user"],
};