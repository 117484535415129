import classNames from 'classnames';
import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import WebChat from './WebChat';
import jIcon from "./assets/images/Jacobs_jmark_black.svg";
import './fabric-icons-inline.css';
import './MinimizableWebChat.css';
import { Tooltip } from 'react-tooltip';


const MinimizableWebChat = () => {
    const [loaded, setLoaded] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [newMessage, setNewMessage] = useState(false);
    const [side, setSide] = useState('right');
    const [token, setToken] = useState();

    useEffect(() => {
        if (isMobile && loaded) {
            const e = document.querySelector('.chat-box');
            if (e) {
                e.style.width = '90%';
            }
        }
    }, [loaded]);

    // To learn about reconnecting to a conversation, see the following documentation:
    // https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-reconnect-to-conversation?view=azure-bot-service-4.0

    const handleFetchToken = useCallback(async () => {
        if (!token) {
            const res = await axios.post('/bot/token');
            const token = res.data;
            setToken(token);
        }
    }, [setToken, token]);

    const handleMaximizeButtonClick = useCallback(async () => {
        setLoaded(true);
        setMinimized(false);
        setNewMessage(false);
    }, [setMinimized, setNewMessage]);

    const handleMinimizeButtonClick = useCallback(() => {
        setLoaded(false);
        setMinimized(true);
        setNewMessage(false);
    }, [setMinimized, setNewMessage]);

    const handleSwitchButtonClick = useCallback(() => {
        setSide(side === 'left' ? 'right' : 'left');
    }, [setSide, side]);

    // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
    //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
    //       When minimized, we still want to maintain that connection while the UI is gone.
    //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

    return (
        <div className="minimizable-web-chat">
            {minimized && (
                <button className="maximize" onClick={handleMaximizeButtonClick}>
                    <span className={token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message'} />
                    {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
                </button>
            )}
            {loaded && (
                <div className={classNames(side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : '')}>
                    <header>
                        <div class="chat-title">
                            <div class="header-circle">
                                <img class="header-icon " src={jIcon} alt="Chat Header Logo Atlas" />
                            </div>
                            <div class="recipient-title">
                                <h1 style={{ fontWeight: 'bold' }}>Atlas</h1>
                            </div>
                        </div>
                        <div className="filler" />
                        {/*<button className="switch" onClick={handleSwitchButtonClick}>*/}
                        {/*    <span className="ms-Icon ms-Icon--Switch" />*/}
                        {/*</button>*/}
                        <button id="btnFeedback">
                            <span className="ms-Icon ms-Icon--ClipboardListQuestion" />
                        </button>
                        <Tooltip anchorSelect="#btnFeedback" place="left" delayHide="1000" clickable>
                            <div>Got a minute to give feedback <a style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer" href="https://jacobsengineering.sharepoint.com/:l:/s/ICDataFabricandAdvancedAnalytics/FOwYnrhugcBPmsmYl_9YLSwBGgK3JzcmeS0r3XfDP5rR_w?nav=MWUwMTU0YzgtMWQ5Yi00YWMzLWFkNDQtMTQwMmY2OTA5OTMx&xsdata=MDV8MDJ8fGQwMTVkYzY0MzY5ZDQ0ZmFiZjYxMDhkY2FmOTQ3YTJifDM3MjQ3Nzk4ZjQyYzQyZmQ4YTM3ZDQ5YzcxMjhkMzZifDB8MHw2Mzg1NzgyOTk0MTk0NzU4MDl8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbTFsWlhScGJtZGZXWHBGZUU1cVpHbFphazEwV1ZSWmVrMTVNREJaVkZrMVRGZEpNMDVIU1hST2VtTXhUVlJrYWxwcVl6SmFiVWt4UUhSb2NtVmhaQzUyTWk5dFpYTnpZV2RsY3k4eE56SXlNak16TVRReE16WXh8OGVjNzZiZjkzY2YzNGQ5YjVkMDkwOGRjYWY5NDdhMjl8MTY1OTNkMGQ0Y2YwNDBkYjgxYTNjY2Y1NTIyYmQxZTM%3D&sdata=ZWpCa1RJL0VmaHpoSDZTSm5XTVZrclFTMG1Md09qdnlyN0RaNjFwS0dldz0%3D&ovuser=37247798-f42c-42fd-8a37-d49c7128d36b%2CGaurav.Chaurasia%40jacobs.com">here</a> and help us improve?</div>
                        </Tooltip>
                        <MobileView>
                            <button onClick={handleMinimizeButtonClick}>
                                <span className="ms-Icon ms-Icon--ChromeClose" />
                            </button>
                        </MobileView>
                    </header>
                    <WebChat
                        className="react-web-chat"
                        onFetchToken={handleFetchToken}
                        token={token} />
                </div>
            )}
            {!minimized && (
                <BrowserView>
                    <button className="minimize" onClick={handleMinimizeButtonClick}>
                        <span className="ms-Icon ms-Icon--ChromeClose" />
                    </button>
                </BrowserView>
            )}
        </div>
    );
};

export default MinimizableWebChat;