import React, { useEffect } from 'react';
import './WebChat.css';
import logo from "./assets/images/Jacobs_logo.svg";

const WebChat = ({ className, onFetchToken, token }) => {

    useEffect(() => {
        onFetchToken();
    }, [onFetchToken]);

    return token ? (
        <div className="content" style={{ height: '100%', width: '100%' }}>
            <iframe title="Atlas" id='tstFrame' src={token} style={{ height: '100%', width: '100%' }}></iframe>
        </div>
    ) : (
        <div className={`${className || ''} connect-spinner`}>
                <div className="content">
                <img src={logo} alt="Jacobs" />
                <p style={{ color: 'white', margin: 'revert' }}>Please wait while we are connecting.</p>
            </div>
        </div>
    );
};

export default WebChat;