import React from 'react';

export const Maximize = ({ color }) => {
  return (
    <svg
      version="1.1"
      width="20"
      height="20"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      
    >
      <g>
        <g >
          <path
            d="m18.5 23h-15c-1.378 0-2.5-1.122-2.5-2.5v-15c0-1.378 1.122-2.5 2.5-2.5h9c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.827 0-1.5.673-1.5 1.5v15c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-9c0-.276.224-.5.5-.5s.5.224.5.5v9c0 1.378-1.122 2.5-2.5 2.5z"
            fill={color}
            data-original="#000000"
          />
        </g>
        <g >
          <path
            d="m22.5 9c-.276 0-.5-.224-.5-.5v-6.5h-6.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.276 0 .5.224.5.5v7c0 .276-.224.5-.5.5z"
            fill={color}
            data-original="#000000"
          />
        </g>
        <g >
          <path
            d="m10.5 14c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l12-12c.195-.195.512-.195.707 0s.195.512 0 .707l-12 12c-.097.097-.225.146-.353.146z"
            fill={color}
            data-original="#000000"
            
          />
        </g>
      </g>
    </svg>
  );
};

Maximize.defaultProps = {
  color: '#767676',
};
