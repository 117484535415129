import axios from 'axios';

export const getReportListService = () => new Promise(async (resolve, reject) => {
    axios.get('/data/GetReportList')
        .then(res => {
            if (res.data?.Status === "500") {
                reject(res.data)
            }
            resolve(res.data)
        })
        .catch(err => reject(err))
})


export const getUserRolesListService = () =>
    new Promise((resolve, reject) => {
        axios.get('/data/GetUserRolesList')
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })


export const getUserDetailsService = () =>
    new Promise((resolve, reject) => {
        axios.get('/data/GetUserDetails')
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })


export const toggleFavouriteService = (item) =>
    new Promise((resolve, reject) => {
        axios.put('/data/ToggleFavorite', {
            "ReportId": `${item.ReportId}`,
            "IsFav": `${item.IsFavFlg === 'Y' ? 'N' : 'Y'}`,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })


export const getReportAccessService = (accessType) =>
    new Promise((resolve, reject) => {
        axios.get(`/data/GetReportAccess?AccessTypeId=${accessType}`)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })


export const getRefreshDatesService = () =>
    new Promise((resolve, reject) => {
        axios.get('/data/GetRefreshDates')
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })

export const getNotificationsService = () =>
    new Promise((resolve, reject) => {
        axios.get('/data/GetNotifications')

            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })

export const getNotificationsUpdateService = (popup, notification) =>
    new Promise((resolve, reject) => {
        axios.post('/data/UpdateNotifications', {
            "PopupFlag": `${popup}`,
            "NotificationFlag": `${notification}`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })